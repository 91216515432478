import { CardError, LoadBar, Sheet, SectionLabel } from 'components'
import { DeliveriesFlagSingleActionPlan } from 'qualityControl/flags/FlagSingleActionPlan'
import FlagSingleComments from 'qualityControl/flags/FlagSingleComments/FlagSingleComments'
import { useEffect } from 'react'
import { formatErrorMessage } from 'utils/formatting'
import { useCurrentFlagContext } from 'qualityControl/flags/contexts/CurrentFlagContext'
import { useRequest } from 'hooks'

const FlagViewer = ({ id }) => {
  const { currentFlag, fetchFlagAction } = useCurrentFlagContext()

  const fetchFlagRequest = useRequest(fetchFlagAction)

  useEffect(() => {
    fetchFlagRequest.makeRequest(id, {
      fields: [
        'id',
        'action_plan',
        {
          viewed_by_users: ['email'],
        },
        {
          comments: [
            'id',
            'body',
            'created_at',
            { viewed_by_users: ['email'] },
            { author: ['id', 'name', 'email'] },
          ],
        },
        { trigger: ['id', 'name'] },
      ],
    })
  }, [id])

  return (
    <Sheet title={currentFlag?.trigger.name}>
      <LoadBar show={fetchFlagRequest.isLoading} />
      <CardError
        hide={!fetchFlagRequest.hasErrors}
        message={formatErrorMessage(fetchFlagRequest.errors)}
      />
      {!!currentFlag?.id && (
        <>
          <SectionLabel primary>Action Plan</SectionLabel>
          <DeliveriesFlagSingleActionPlan />
          <SectionLabel accent>Resolution and Response</SectionLabel>
          <FlagSingleComments />
        </>
      )}
    </Sheet>
  )
}

export default FlagViewer
