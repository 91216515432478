import { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { View } from 'components'
import InspectedInbox from 'qualityControl/inbox/InspectedInbox'
import { useCurrent } from 'contexts/index'
import CallSessionProvider from 'phone_banks/components/CallSessionContext/CallSessionContext'
import UserPacketAssignment from './packetAssignment/UserPacketAssignment'
import Schedule from './schedule/Schedule'
import Manage from './Manage'
import Inbox from './inbox/Inbox'
import Gallery from './gallery/Gallery/Gallery'
import { GroupPacketAssignment } from './packetAssignment/GroupPacketAssignment'
import { FlagsReviewMain } from './flags/FlagsReviewMain'
import FlagSingleMain from './flags/FlagSingleMain'
import PhoneVerificationCallSession from './scanReview/phoneVerification/callSession/PhoneVerificationCallSession/PhoneVerificationCallSession'
import Scripts from './callScripts'
import TableManager from './flags/TableManager'
import PledgeCardGallery from './pledgeCardGallery/PledgeCardGallery'
import { QualityControlProvider } from './QualityControlContext'
import { PacketAssignmentProvider } from './packetAssignment/PacketAssignmentContext'
import { InboxProvider } from './inbox/InboxContext'
import CallRecordings from './callRecordings/CallRecordings'
import { FlagsFilteredListContextProvider } from './flags/contexts/FlagsFilteredListContext'

const QualityControl = ({ match }) => {
  const { refreshCurrentRoles } = useCurrent()
  useEffect(() => {
    refreshCurrentRoles()
  }, [])

  return (
    <QualityControlProvider>
      <PacketAssignmentProvider>
        <InboxProvider>
          <FlagsFilteredListContextProvider>
            <Switch>
              <Route
                exact
                path={`${match.url}/manage`}
                render={props => (
                  <View>
                    <Manage history={props.history} />
                  </View>
                )}
              />
              <Route
                path={`${match.url}/call_scripts`}
                render={({ match }) => <Scripts match={match} />}
              />
              <Route
                exact
                path={`${match.url}/manage/schedule`}
                render={props => (
                  <View>
                    <Schedule history={props.history} />
                  </View>
                )}
              />
              <Route
                path={`${match.url}/manage/assign/:staffId/inbox/:packetId?`}
                render={() => (
                  <View>
                    <InspectedInbox />
                  </View>
                )}
              />
              <Route
                exact
                path={`${match.url}/inbox/:packetId/phone_verification_session/:mode`}
                render={() => (
                  <CallSessionProvider.Standard>
                    <PhoneVerificationCallSession />
                  </CallSessionProvider.Standard>
                )}
              />
              <Route
                path={`${match.url}/inbox/:packetId?`}
                render={() => (
                  <View>
                    <Inbox />
                  </View>
                )}
              />
              <Route
                path={`${match.url}/gallery/:shiftId?`}
                render={() => <Gallery />}
              />
              <Route
                path={`${match.url}/pledge_gallery/:packetId?`}
                render={() => <PledgeCardGallery />}
              />
              <Route
                exact
                path={`${match.url}/manage/assign`}
                render={props => (
                  <View>
                    <GroupPacketAssignment history={props.history} />
                  </View>
                )}
              />
              <Route
                exact
                path={`${match.url}/manage/assign/:id`}
                render={() => (
                  <View>
                    <UserPacketAssignment />
                  </View>
                )}
              />
              <Route
                exact
                path={`${match.url}/manage/call_recordings`}
                component={CallRecordings}
              />
              <Route
                exact
                path={`${match.url}/flags/:type`}
                render={props => {
                  const { type } = props.match.params

                  switch (type) {
                    case 'review': {
                      return (
                        <View>
                          <FlagsReviewMain />
                        </View>
                      )
                    }
                    case 'start': {
                      return (
                        <View>
                          <TableManager history={props.history} />
                        </View>
                      )
                    }
                  }
                }}
              />
              <Route
                exact
                path="/quality_control/flags/:type/:flagId/:originalType/:packetId?/:reportOriginParams?"
                component={FlagSingleMain}
              />
            </Switch>
          </FlagsFilteredListContextProvider>
        </InboxProvider>
      </PacketAssignmentProvider>
    </QualityControlProvider>
  )
}

export default QualityControl
