import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useRoutePathParams, useReactRouter } from 'hooks/router'
import { getCurrentPacket } from 'store/qcScans/reducer'
import { FlagPacketViewer } from '../scanReview/PacketViewer'
import PacketAndScanFetcher from '../PacketAndScanFetcher'
import FlagSinglePacketList from './FlagSinglePacketList'
import { useCurrentFlagContext } from './contexts/CurrentFlagContext'
import { getFlagPackets } from './utils'

const FlagSinglePacketViewer = ({ currentPacket, refProp }) => {
  const { t } = useTranslation()
  const { history } = useReactRouter()
  const [{ packetId }, replaceRouteParams] = useRoutePathParams()

  const isPacketLoaded = +packetId === currentPacket.id

  const { currentFlag } = useCurrentFlagContext()
  const packets = getFlagPackets(currentFlag)
  const packetIds = packets.map(({ id }) => id)
  const firstPacketId = packetIds.at(0)

  const isValidPacket = packetIds.includes(+packetId)

  useEffect(() => {
    if (isValidPacket) return

    const route = replaceRouteParams({ packetId: firstPacketId || undefined })
    history.push(route)
  }, [isValidPacket, firstPacketId])

  const headerMessage = `${t('Packet')}${packetIds.length ? 's' : ''} ${t(
    'that triggered this issue'
  )}`
  const sidebarStatusClassName = isValidPacket ? 'inbox-viewer--open' : ''

  return (
    <div ref={refProp}>
      <div className="inbox-viewer__sidebar__header--full-width">
        <h2>{headerMessage}</h2>
      </div>
      <div className={`inbox-viewer ${sidebarStatusClassName}`}>
        <div className="inbox-viewer__main">
          <FlagSinglePacketList />
        </div>
        <div className="inbox-viewer__sidebar">
          {isValidPacket && (
            <PacketAndScanFetcher>
              {isPacketLoaded && (
                <FlagPacketViewer packetId={+packetId} isOpen />
              )}
            </PacketAndScanFetcher>
          )}
        </div>
      </div>
    </div>
  )
}

export default connect(state => ({
  currentPacket: getCurrentPacket(state),
}))(FlagSinglePacketViewer)
