import { useEffect } from 'react'
import {
  CanvasserSelectField,
  TurfSelectField as TurfResourceSelect,
} from 'components'
import {
  TextField,
  DateField,
  SelectField,
  Checkbox,
  Switch,
  useToast,
} from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'
import { groupBy, sumBy } from 'lodash'
import moment from 'moment'
import { useCurrent, useTurfs } from 'contexts/index'
import { useRequest } from 'hooks'
import { fetchFlagTriggersWithCounts } from 'requests/flags'
import { getFilteredFlagsRequestParams } from './contexts/utils'
import { useFlagsFilteredListContext } from './contexts/FlagsFilteredListContext'
import { TAB_NAME_MAPPING } from './TableManagerTabs/TableManagerTabs'

export const TechnicalFlagSwitchControl = () => {
  const { t } = useTranslation()
  const { filters, setFlagFilter } = useFlagsFilteredListContext()

  return (
    <Switch
      id="technical-flags"
      name="technical-flags"
      label={t('Technical Flags')}
      onChange={() => {
        setFlagFilter('technicalFlag', !filters.technicalFlag)
      }}
      value={filters.technicalFlag}
    />
  )
}

export const CanvasserFlagSwitchControl = () => {
  const { t } = useTranslation()
  const { filters, setFlagFilter } = useFlagsFilteredListContext()

  return (
    <Switch
      id="canvasser-flags"
      name="canvasser-flags"
      label={t('Canvasser flags')}
      onChange={() => {
        setFlagFilter('canvasserFlag', !filters.canvasserFlag)
      }}
      value={filters.canvasserFlag}
    />
  )
}

export const TurfSelectControl = () => {
  const { t } = useTranslation()
  const { filters, setFlagFilter } = useFlagsFilteredListContext()
  const { refreshCurrentTurfs, turfRefreshIsLoading } = useTurfs()

  useEffect(() => {
    refreshCurrentTurfs()
  }, [])

  if (turfRefreshIsLoading) return null

  return (
    <TurfResourceSelect
      label={t('Turf')}
      onSelect={value => setFlagFilter('turf', value)}
      value={filters.turf}
      isExpanded
    />
  )
}

const BASE_FLAG_OPTION = { key: 'Show all', value: 'all' }

export const FlagTypeSelectControl = ({ activeTab }) => {
  const { t } = useTranslation()
  const { currentTurfPerformsExternalQC } = useCurrent()
  const { filters, setFlagFilter, listPages } = useFlagsFilteredListContext()
  const { setToast } = useToast()

  const fetchFlagTriggersRequest = useRequest(fetchFlagTriggersWithCounts, {
    onError: () => {
      setToast({
        message: t('Failed to get flag trigger filter options'),
        variant: 'error',
      })
    },
  })

  useEffect(() => {
    const tab = TAB_NAME_MAPPING[activeTab] || 'group'
    fetchFlagTriggersRequest.makeRequest(
      getFilteredFlagsRequestParams(
        tab,
        filters,
        listPages[tab],
        [
          {
            column: 'trigger_name',
          },
        ],
        currentTurfPerformsExternalQC
      )
    )
  }, [activeTab, filters, currentTurfPerformsExternalQC])

  const flagTriggers = fetchFlagTriggersRequest.response || []

  const triggersGroupedByName = groupBy(flagTriggers, 'name')

  const triggerOptions = Object.entries(triggersGroupedByName).map(
    ([name, triggers]) => ({
      key: `${name} (${sumBy(triggers, 'count')})`,
      value: name,
      disabled: !triggers.some(({ count }) => count > 0),
    })
  )

  return (
    <SelectField
      id="flag-type-search-select"
      label={t('Flag type')}
      value={filters.flagName}
      options={[BASE_FLAG_OPTION, ...triggerOptions].map(opt => ({
        label: opt.key,
        value: opt.value,
      }))}
      onSelect={value => setFlagFilter('flagName', value)}
    />
  )
}

export const CanvasserSelectControl = () => {
  const { filters, setFlagFilter } = useFlagsFilteredListContext()

  const canvasserFilter = filters.canvasser
  return (
    <CanvasserSelectField
      clearable
      filters={
        filters.turf
          ? [
              {
                column: 'turf_id',
                operator: 'is',
                param: filters.turf,
              },
            ]
          : undefined
      }
      selectedCanvasser={canvasserFilter ? { id: canvasserFilter } : undefined}
      onSelect={canvasser => setFlagFilter('canvasser', canvasser?.id)}
    />
  )
}

export const FlagIdInputControl = () => {
  const { filters, setFlagFilter } = useFlagsFilteredListContext()
  return (
    <TextField
      id="flag-id"
      label="Flag ID"
      value={filters.id}
      onChange={val => setFlagFilter('id', val.replace(/\D/g, ''))}
    />
  )
}

const SORT_BY_OPTIONS = [
  {
    key: 'None',
    value: 'none',
  },
  {
    key: 'Flag ID',
    value: 'id',
  },
  {
    key: 'Date flagged',
    value: 'created_at',
  },
  { key: 'Date commented', value: 'last_comment' },
]

export const SortByFlagControl = () => {
  const { t } = useTranslation()
  const { filters, setFlagFilter } = useFlagsFilteredListContext()

  return (
    <SelectField
      id="sort-by-search-select"
      label={t('Sort by')}
      value={filters.sortBy}
      options={SORT_BY_OPTIONS.map(opt => ({
        label: opt.key,
        value: opt.value,
      }))}
      onSelect={value => setFlagFilter('sortBy', value)}
    />
  )
}

export const StartDatePickerControl = ({ activeTab }) => {
  const { t } = useTranslation()
  const { filters, setFlagFilter } = useFlagsFilteredListContext()

  const defaultDate = moment().startOf('week').toISOString()
  const startDate =
    activeTab === 3 ? filters.startDate || defaultDate : filters.startDate

  return (
    <DateField
      label={t('Start date')}
      value={startDate}
      onChange={value => setFlagFilter('startDate', value)}
    />
  )
}

export const EndDatePickerControl = ({ activeTab }) => {
  const { t } = useTranslation()
  const { filters, setFlagFilter } = useFlagsFilteredListContext()

  const defaultDate = moment().startOf('week').toISOString()
  const endDate =
    activeTab === 3 ? filters.endDate || defaultDate : filters.endDate

  return (
    <DateField
      label={t('End date')}
      value={endDate}
      onChange={value => setFlagFilter('endDate', value)}
    />
  )
}

export const NewCommentFilterControl = () => {
  const { t } = useTranslation()
  const { filters, setFlagFilter } = useFlagsFilteredListContext()

  return (
    <Checkbox
      id="new-comment-checkbox"
      name="new-comment-checkbox"
      label={t('Only display flags with new comments')}
      onChange={() => {
        setFlagFilter('newComments', !filters.newComments)
      }}
      checked={filters.newComments || true}
    />
  )
}
